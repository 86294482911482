import React, { useEffect, useMemo, useState } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import abi from "../pages/bbb/bbb.json";
import keccak256 from "keccak256";
import MerkleTree from "merkletreejs";
import T1WL from "../pages/bbb/T1WL";
import T2WL from "../pages/bbb/T2WL";
import T3WL from "../pages/bbb/T3WL";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FaDiscord, FaTwitter } from "react-icons/fa";

function shorten(text) {
  return text.substring(0, 5) + "..." + text.substring(39, 42);
}

export default function Navbar() {
  const [show, setShow] = useState(null);
  const [profile, setProfile] = useState(false);
  const [product, setProduct] = useState(false);
  const [deliverables, setDeliverables] = useState(false);
  let provider;
  let providerWC = new WalletConnectProvider({
    rpc: { 43114: "https://api.avax.network/ext/bc/C/rpc" },
    chainId: 43114,
  });
  window.ethereum
    ? (provider = new ethers.providers.Web3Provider(window.ethereum))
    : (provider = new ethers.providers.Web3Provider(providerWC));

  const connect = async () => {
    window.ethereum
      ? await provider.send("eth_requestAccounts", [])
      : await providerWC.enable();
    await changeNetwork();
  };
  // const providerMainnet = new ethers.getDefaultProvider(
  //   "https://api.avax.network/ext/bc/C/rpc"
  // );
  // const providerTestnet = new ethers.getDefaultProvider(
  //   "https://api.avax-test.network/ext/bc/C/rpc"
  // );
  // const contractAddress = "0x84FaB74795F51C3208379924c76abE6e5A36D392";
  const signer = provider.getSigner();
  // const contract = new ethers.Contract(contractAddress, abi, providerMainnet);
  // const withSigner = contract.connect(signer);
  // const [amount, setAmount] = useState(1);
  // const [royalty, setRoyalty] = useState(500);
  // const [price, setPrice] = useState();
  // const [wlPrice, setWlPrice] = useState();
  // const [maxMintable, setMaxMintable] = useState(3000);
  // const [max, setMax] = useState(1);
  // const [maxWL, setMaxWl] = useState();
  // const [wlTime, setWlTime] = useState();
  // const [publicTime, setPublicTime] = useState();
  // const [isDisabled, setDisabled] = useState(false);
  // const [connectMessage, setConnectMessage] = useState("Connect Wallet");
   const [address, setAddress] = useState("");
 const [network, setNetwork] = useState(0);
  // const [nothing, doNothing] = useState();

  // let totalPrice = price * amount;

  // const [minted, setMinted] = useState(0);
  // const [revealed, setRevealed] = useState(0);
  // const [whitelisted, setWhitelisted] = useState();

  // const buf2hex = (x) => "0x" + x.toString("hex");
  // const leavesT1 = T1WL.map((x) => keccak256(x));
  // const leavesT2 = T2WL.map((x) => keccak256(x));
  // const leavesT3 = T3WL.map((x) => keccak256(x));
  // const treeT1 = new MerkleTree(leavesT1, keccak256, { sortPairs: true });
  // const treeT2 = new MerkleTree(leavesT2, keccak256, { sortPairs: true });
  // const treeT3 = new MerkleTree(leavesT3, keccak256, { sortPairs: true });
  // const leaf = keccak256(address).toString("hex");

  // let proof;
  // if (T3WL.includes(address)) {
  //   proof = treeT3.getProof(leaf).map((x) => buf2hex(x.data));
  // } else if (T2WL.includes(address)) {
  //   proof = treeT2.getProof(leaf).map((x) => buf2hex(x.data));
  // } else {
  //   proof = treeT1.getProof(leaf).map((x) => buf2hex(x.data));
  // }

  // useMemo(() => {
  //   contract.lastTokenRevealed().then((e) => setRevealed(parseInt(e)));
  //   contract.totalSupply().then((e) => setMinted(parseInt(e._hex)));
  //   contract
  //     .isWhitelisted(proof, address)
  //     .then((e) => setWhitelisted(parseInt(e._hex)));
  //   contract.price().then((e) => setPrice(ethers.utils.formatEther(e)));
  //   contract
  //     .tierPrice(whitelisted - 1)
  //     .then((e) => setWlPrice(ethers.utils.formatEther(e)));
  //   contract.tierMints(whitelisted - 1).then((e) => setMaxWl(parseInt(e._hex)));
  //   contract.maxPerTX().then((e) => setMax(parseInt(e._hex)));
  //   contract.publicTime().then((e) => setPublicTime(parseInt(e._hex)));
  //   contract.wlTime().then((e) => setWlTime(parseInt(e._hex)));
  //   contract.maxMintable().then((e) => setMaxMintable(parseInt(e._hex)));
  // }, [address, contract, proof, whitelisted]);

  useEffect(() => {
    // if (address) {
    //   setConnectMessage("Connected as: " + shorten(address));
    // }
    signer.getAddress().then((value) => setAddress(value));
    provider.getNetwork().then((value) => setNetwork(value.chainId));
  }, [provider, signer]);

  // function handleError(err) {
  //   setDisabled(false);
  //   console.log(err);
  //   errorToast(err.reason || err.data.message);
  // }
  // const txToast = (txhash) => {
  //   const id = Date.now().toString();
  //   toast({
  //     duration: 18000,
  //     position: "bottom-left",
  //     id: id,
  //     render: () => (
  //       <>
  //         <Box
  //           shadow={"dark-lg"}
  //           rounded={"3xl"}
  //           bgColor="black"
  //           border={"2px"}
  //           padding={"0.5"}
  //           justifyContent="center"
  //           justifyItems={"center"}
  //           justifySelf={"center"}
  //           alignContent={"center"}
  //           alignItems={"center"}
  //           alignSelf={"center"}
  //           textAlign="center"
  //           verticalAlign="center"
  //         >
  //           <HStack>
  //             <Heading flex={1} fontSize={"2xl"}>
  //               Success!
  //             </Heading>
  //             <IconButton
  //               onClick={() => {
  //                 toast.close(id);
  //               }}
  //               rounded="full"
  //               colorScheme={"blackAlpha"}
  //             >
  //               x
  //             </IconButton>
  //           </HStack>
  //           <Text>
  //             view your tx :{" "}
  //             <Link
  //               textColor={"red"}
  //               href={"https://snowtrace.io/tx/" + txhash}
  //               isExternal
  //             >
  //               Link
  //             </Link>{" "}
  //           </Text>
  //         </Box>
  //       </>
  //     ),
  //   });
  // };

  // const mint = async () => {
  //   if (network == 43114) {
  //     setDisabled(true);
  //     const tx = await withSigner
  //       .mint(amount, royalty, {
  //         value: ethers.utils.parseEther(totalPrice.toString()),
  //       })
  //       .catch(handleError);

  //     const receipt = await tx.wait().catch(handleError);
  //     setDisabled(false);
  //     console.log(receipt);
  //     if (receipt.status == 1) txToast(receipt.transactionHash);
  //   } else {
  //     changeNetwork();
  //     errorToast("change network to Avalanche mainnet");
  //   }
  // };
  // const mint1 = async () => {
  //   if (network == 43114) {
  //     setDisabled(true);
  //     const tx = await withSigner
  //       .tier1Mint(amount, royalty, proof, {
  //         value: ethers.utils.parseEther(totalPrice.toString()),
  //       })
  //       .catch(handleError);

  //     const receipt = await tx.wait().catch(handleError);
  //     setDisabled(false);
  //     console.log(receipt);
  //     if (receipt.status == 1) txToast(receipt.transactionHash);
  //   } else {
  //     changeNetwork();
  //     errorToast("change network to Avalanche mainnet");
  //   }
  // };
  // const mint2 = async () => {
  //   if (network == 43114) {
  //     setDisabled(true);
  //     const tx = await withSigner
  //       .tier2Mint(amount, royalty, proof, {
  //         value: ethers.utils.parseEther(totalPrice.toString()),
  //       })
  //       .catch(handleError);

  //     const receipt = await tx.wait().catch(handleError);
  //     setDisabled(false);
  //     console.log(receipt);
  //     if (receipt.status == 1) txToast(receipt.transactionHash);
  //   } else {
  //     changeNetwork();
  //     errorToast("change network to Avalanche mainnet");
  //   }
  // };
  // const mint3 = async () => {
  //   if (network == 43114) {
  //     setDisabled(true);
  //     const tx = await withSigner
  //       .tier3Mint(amount, royalty, proof)
  //       .catch(handleError);

  //     const receipt = await tx.wait().catch(handleError);
  //     setDisabled(false);
  //     console.log(receipt);
  //     if (receipt.status == 1) txToast(receipt.transactionHash);
  //   } else {
  //     changeNetwork();
  //     errorToast("change network to Avalanche mainnet");
  //   }
  // };

  // const toast = useToast();

  const changeNetwork = async () => {
    if (network != 43114 && window.ethereum) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0xa86a",
          },
        ],
      });
    } else if (network != 43114 && !window.ethereum) {
      await providerWC.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0xa86a",
          },
        ],
      });
    }
  };
  // const errorToast = (reason) => {
  //   const id = Date.now().toString();
  //   toast({
  //     isClosable: true,
  //     duration: 18000,
  //     position: "bottom-left",
  //     id: id,
  //     render: () => (
  //       <>
  //         <Box
  //           shadow={"dark-lg"}
  //           rounded={"3xl"}
  //           bgColor="black"
  //           border={"2px"}
  //           padding={"0.5"}
  //           justifyContent="center"
  //           justifyItems={"center"}
  //           justifySelf={"center"}
  //           alignContent={"center"}
  //           alignItems={"center"}
  //           alignSelf={"center"}
  //           textAlign="center"
  //           verticalAlign="center"
  //         >
  //           <HStack>
  //             <Heading flex={1} fontSize={"2xl"}>
  //               Error!
  //             </Heading>
  //             <IconButton
  //               onClick={() => {
  //                 toast.close(id);
  //               }}
  //               rounded="full"
  //               colorScheme={"blackAlpha"}
  //             >
  //               x
  //             </IconButton>
  //           </HStack>
  //           <Text>{reason}</Text>
  //         </Box>
  //       </>
  //     ),
  //   });
  // };
  // const snowtraceLink =
  //   "https://snowtrace.io/address/" + contractAddress + "#code";
  // const joepegsLink = "https://joepegs.com/collections/" + contractAddress;
  // function MintButton() {
  //   const publicSale = publicTime * 1000 < Date.now();
  //   const privateSale = wlTime * 1000 < Date.now();
  //   switch (whitelisted) {
  //     case 3:
  //       !privateSale ? setDisabled(true) : doNothing(0);
  //       totalPrice = amount * wlPrice;
  //       return (
  //         <>
  //           <Text>
  //             WL Tier 3 Mint
  //             <br />
  //             Max {maxWL} NFT
  //           </Text>

  //           <Button
  //             border={"2px"}
  //             colorScheme={"blackAlpha"}
  //             rightIcon={isDisabled && privateSale ? <Spinner /> : ""}
  //             disabled={isDisabled}
  //             onClick={() => mint3()}
  //           >
  //             {" "}
  //             {isDisabled
  //               ? !privateSale
  //                 ? "WL sale starts on Dec 1st 4 PM UTC "
  //                 : "waiting for tx"
  //               : "mint " + amount + " BBB for " + totalPrice + " AVAX"}{" "}
  //           </Button>
  //         </>
  //       );

  //     case 2:
  //       !privateSale ? setDisabled(true) : doNothing(0);
  //       totalPrice = amount * wlPrice;
  //       return (
  //         <>
  //           <Text>
  //             WL Tier 2 Mint
  //             <br />
  //             Max {maxWL} NFTs
  //           </Text>

  //           <Button
  //             border={"2px"}
  //             colorScheme={"blackAlpha"}
  //             rightIcon={isDisabled && privateSale ? <Spinner /> : ""}
  //             disabled={isDisabled}
  //             onClick={() => mint2()}
  //           >
  //             {" "}
  //             {isDisabled
  //               ? !privateSale
  //                 ? "WL sale starts on Dec 1st 4 PM UTC "
  //                 : "waiting for tx"
  //               : "mint " + amount + " BBB for " + totalPrice + " AVAX"}{" "}
  //           </Button>
  //         </>
  //       );
  //     case 1:
  //       !privateSale ? setDisabled(true) : doNothing(0);
  //       totalPrice = amount * wlPrice;
  //       return (
  //         <>
  //           <Text>
  //             WL Tier 1 Mint
  //             <br />
  //             Max {maxWL} NFTs
  //           </Text>
  //           <Button
  //             border={"2px"}
  //             colorScheme={"blackAlpha"}
  //             rightIcon={isDisabled && privateSale ? <Spinner /> : ""}
  //             disabled={isDisabled}
  //             onClick={() => mint1()}
  //           >
  //             {" "}
  //             {isDisabled
  //               ? !privateSale
  //                 ? "WL sale starts on Dec 1st 4 PM UTC "
  //                 : "waiting for tx"
  //               : "mint " + amount + " BBB for " + totalPrice + " AVAX"}{" "}
  //           </Button>
  //         </>
  //       );
  //     case 0:
  //       !publicSale ? setDisabled(true) : doNothing(0);
  //       totalPrice = amount * price;
  //       return (
  //         <>
  //           <Text>Public Mint</Text>
  //           <Text>Max {max} NFTs per TX</Text>
  //           <Button
  //             border={"2px"}
  //             colorScheme={"blackAlpha"}
  //             rightIcon={isDisabled && publicSale ? <Spinner /> : ""}
  //             disabled={isDisabled}
  //             onClick={() => mint()}
  //           >
  //             {" "}
  //             {isDisabled
  //               ? !publicSale
  //                 ? "Public sale starts on Dec 1st 6 PM UTC "
  //                 : "waiting for tx"
  //               : "mint " + amount + " BBB for " + totalPrice + " AVAX"}{" "}
  //           </Button>
  //         </>
  //       );
  //     default:
  //       totalPrice = amount * price;
  //       return (
  //         <>
  //           <Button
  //             border={"2px"}
  //             colorScheme={"blackAlpha"}
  //             rightIcon={isDisabled && publicSale ? <Spinner /> : ""}
  //             disabled={isDisabled}
  //             onClick={() => {
  //               connect();
  //               errorToast("change network to Avalanche mainnet");
  //             }}
  //           >
  //             Connect/Change Network
  //           </Button>
  //         </>
  //       );
  //   }
  
  return (
    <>
      <div className="h-full w-full">
        {/* Code block starts */}
        <nav className="xl:block hidden py-0 lg:py-12 max-w-4xl mx-auto">
          <div className="mx-auto container px-6 py-2 xl:py-0">
            <div className="flex items-center justify-between">
              <div className="inset-y-0 left-0 flex items-center xl:hidden">
                <div className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-100 focus:outline-none transition duration-150 ease-in-out">
                  <div className="visible xl:hidden">
                    <ul className="p-2 border-r bg-white absolute rounded left-0 right-0 shadow mt-8 md:mt-8 hidden">
                      <li className="flex xl:hidden cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-grid"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <rect x={4} y={4} width={6} height={6} rx={1} />
                            <rect x={14} y={4} width={6} height={6} rx={1} />
                            <rect x={4} y={14} width={6} height={6} rx={1} />
                            <rect x={14} y={14} width={6} height={6} rx={1} />
                          </svg>
                          <span className="ml-2 font-bold">Dashboard</span>
                        </div>
                      </li>
                      <li className="flex xl:hidden flex-col cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none flex justify-center">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-puzzle"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <path d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1" />
                          </svg>
                          <span className="ml-2 font-bold">Products</span>
                        </div>
                      </li>
                      <li className="flex xl:hidden cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 flex items-center focus:text-indigo-700 focus:outline-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-compass"
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <polyline points="8 16 10 10 16 8 14 14 8 16" />
                          <circle cx={12} cy={12} r={9} />
                        </svg>
                        <span className="ml-2 font-bold">Performance</span>
                      </li>
                      <li className="border-b border-gray-300 flex xl:hidden cursor-pointer text-gray-600 text-sm leading-3 tracking-normal pt-2 pb-4 hover:text-indigo-700 flex items-center focus:text-indigo-700 focus:outline-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-code"
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <polyline points="7 8 3 12 7 16" />
                          <polyline points="17 8 21 12 17 16" />
                          <line x1={14} y1={4} x2={10} y2={20} />
                        </svg>
                        <span className="ml-2 font-bold">Deliverables</span>
                      </li>
                      <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-indigo-700 flex items-center focus:text-indigo-700 focus:outline-none">
                        <div className="flex items-center">
                          <div className="w-12 cursor-pointer flex text-sm border-2 border-transparent rounded focus:outline-none focus:border-white transition duration-150 ease-in-out">
                            <img
                              className="rounded h-10 w-10 object-cover"
                              src="https://tuk-cdn.s3.amazonaws.com/assets/components/horizontal_navigation/hn_1.png"
                              alt="logo"
                            />
                          </div>
                          <p className="text-sm ml-2 cursor-pointer">
                            Jane Doe
                          </p>
                          <div className="sm:ml-2 text-white relative">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-chevron-down cursor-pointer"
                              width={20}
                              height={20}
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <polyline points="6 9 12 15 18 9" />
                            </svg>
                          </div>
                        </div>
                      </li>
                      <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-user"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <circle cx={12} cy={7} r={4} />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          </svg>
                          <span className="ml-2">Profile</span>
                        </div>
                      </li>
                    </ul>
                    <svg
                      onclick="MenuHandler(this,true)"
                      aria-haspopup="true"
                      aria-label="Main Menu"
                      xmlns="http://www.w3.org/2000/svg"
                      className="show-m-menu icon icon-tabler icon-tabler-menu"
                      width={28}
                      height={28}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <line x1={4} y1={8} x2={20} y2={8} />
                      <line x1={4} y1={16} x2={20} y2={16} />
                    </svg>
                  </div>
                  <div
                    className="hidden close-m-menu text-gray-700"
                    onclick="MenuHandler(this,false)"
                  >
                    <svg
                      aria-label="Close"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <line x1={18} y1={6} x2={6} y2={18} />
                      <line x1={6} y1={6} x2={18} y2={18} />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex w-full sm:w-auto items-center sm:items-stretch justify-end sm:justify-start">
              </div>
              <div className="flex">
                <div className="hidden xl:flex items-center">
                  <button
                    className="focus:outline-none bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl transition duration-150 ease-in-out hover:bg-gray-300 text-gray-600 px-8 py-3 font-extrabold"
                    onClick={() => connect()}
                  >
                    {address ? shorten(address) : "Connect Wallet"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <nav>
          <div className="py-4 px-6 w-full flex xl:hidden justify-between items-center bg-gradient-to-b from-white/60 to-white/30 border-b-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg">
            <div className="flex items-center">
              <div className="relative mr-6 ">
                <button
                  className="focus:outline-none bg-gray-100 border-gray-300 border transition duration-150 ease-in-out hover:bg-gray-300 rounded text-gray-700 px-6 py-3 text-sm"
                  onClick={() => connect()}
                >
                  {address ? shorten(address) : "Connect Wallet"}
                </button>
              </div>
              <div
                id="menu"
                className="text-gray-800 mr-4"
                onClick={() => setShow(!show)}
              >
                {show ? (
                  ""
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-menu-2"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1={4} y1={6} x2={20} y2={6} />
                    <line x1={4} y1={12} x2={20} y2={12} />
                    <line x1={4} y1={18} x2={20} y2={18} />
                  </svg>
                )}
              </div>
            </div>
          </div>
          {/*Mobile responsive sidebar*/}
          <div
            className={
              show
                ? "w-full xl:hidden h-full absolute z-50 top-0  transform  translate-x-0 "
                : "   w-full xl:hidden h-full absolute z-50 top-0 transform -translate-x-full"
            }
          >
            <div
              className="bg-gray-800 opacity-50 w-full h-full"
              onClick={() => setShow(!show)}
            />
            <div className="w-full fixed overflow-y-auto z-40 top-0 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg shadow h-full flex-col justify-between xl:hidden pb-4 transition duration-150 ease-in-out">
              <div className="px-6 h-full">
                <div className="flex flex-col justify-between h-full w-full">
                  <div>
                    <div className="mt-6 flex w-full items-center justify-between">
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center">
                          <a href="/">
                            <img
                              src="./logo.png"
                              alt="BabBizBones"
                              className="w-32"
                            />
                          </a>
                        </div>
                        <div
                          id="cross"
                          className="text-gray-800"
                          onClick={() => setShow(!show)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-x"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <line x1={18} y1={6} x2={6} y2={18} />
                            <line x1={6} y1={6} x2={18} y2={18} />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full pt-4">
                    <div className="border-t border-gray-300">
                      <div className="w-full flex items-center justify-between pt-1">
                        <div className="flex items-center">
                          <button
                            className="focus:outline-none bg-gray-100 border-gray-300 border transition duration-150 ease-in-out hover:bg-gray-300 rounded text-gray-700 px-6 py-3 text-sm font-bold"
                            onClick={() => connect()}
                          >
                            {address ? shorten(address) : "Connect Wallet"}
                          </button>
                        </div>
                        <ul className="flex gap-2">
                          <li className="cursor-pointer text-indigo-700 pt-5 pb-3">
                            <div className="w-6 h-6 md:w-8 md:h-8">
                              <a
                                href="https://twitter.com/BadBizBones"
                                target="_blank" rel="noreferrer"
                              >
                                <FaTwitter size={24} />
                              </a>
                            </div>
                          </li>
                          <li className="cursor-pointer text-indigo-700 pt-5 pb-3 pl-3">
                            <div className="w-6 h-6 md:w-8 md:h-8">
                              <a
                                href="https://discord.gg/FHeQdkGZ4A"
                                target="_blank" rel="noreferrer"
                              >
                                <FaDiscord size={24} />
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* Code block ends */}
      </div>
    </>
  );
}
