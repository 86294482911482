import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxGroup,
  Container,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  List,
  ListItem,
  SimpleGrid,
  Text,
  Link,
  useToast,
  VStack,
} from "@chakra-ui/react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import * as constants from "../bbb/constants";
import { CloseIcon } from "@chakra-ui/icons";
import { json } from "react-router-dom";
import traitRerollAbi from "../../abis/traitRerollAbi.json";
import bbbAbi from "../../abis/bbb.json";
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { IpfsImage } from "react-ipfs-image";
import { Card } from "@chakra-ui/card";
import Navbar from "../../components/Navbar";

function shorten(text) {
  return text.substring(0, 5) + "..." + text.substring(39, 42);
}
let provider;
let providerWC = new WalletConnectProvider({
  rpc: { 43114: "https://api.avax.network/ext/bc/C/rpc" },
  chainId: 43114,
});
window.ethereum
  ? (provider = new ethers.providers.Web3Provider(window.ethereum))
  : (provider = new ethers.providers.Web3Provider(providerWC));

const connect = async () => {
  window.ethereum
    ? await provider.send("eth_requestAccounts", [])
    : await providerWC.enable();
};
const providerMainnet = new ethers.getDefaultProvider(
  "https://api.avax.network/ext/bc/C/rpc"
);
const providerTestnet = new ethers.getDefaultProvider(
  "https://api.avax-test.network/ext/bc/C/rpc"
);
const signer = provider.getSigner();


export default function Wardrobe() {
  const [network, setNetwork] = useState();

  const changeNetwork = async () => {
    if (network != 43114 && window.ethereum) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0xa86a",
          },
        ],
      });
    } else if (network != 43114 && !window.ethereum) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0xa86a",
          },
        ],
      });
    }
  };

  const toast = useToast();
  const infoToast = (heading, info) => {
    const id = Date.now().toString();
    toast({
      duration: 18000,
      position: "bottom-left",
      id: id,
      render: () => (
        <>
          <Box
            shadow={"dark-lg"}
            rounded={"3xl"}
            bgColor="black"
            border={"2px"}
            padding={"0.5"}
            justifyContent="center"
            justifyItems={"center"}
            justifySelf={"center"}
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            textAlign="center"
            verticalAlign="center"
          >
            <HStack>
              <Heading flex={1} fontSize={"2xl"}>
                {heading}
              </Heading>
              <IconButton
                onClick={() => {
                  toast.close(id);
                }}
                rounded="full"
                colorScheme={"blackAlpha"}
              >
                <CloseIcon />
              </IconButton>
            </HStack>
            <Text>{info}</Text>
          </Box>
        </>
      ),
    });
  };
  const txToast = (txhash) => {
    const id = Date.now().toString();
    toast({
      duration: 18000,
      position: "bottom-left",
      id: id,
      render: () => (
        <>
          <Box
            shadow={"dark-lg"}
            rounded={"3xl"}
            bgColor="black"
            border={"2px"}
            padding={"0.5"}
            justifyContent="center"
            justifyItems={"center"}
            justifySelf={"center"}
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            textAlign="center"
            verticalAlign="center"
          >
            <HStack>
              <Heading flex={1} fontSize={"2xl"}>
                Success!
              </Heading>
              <IconButton
                onClick={() => {
                  toast.close(id);
                }}
                rounded="full"
                colorScheme={"blackAlpha"}
              >
                <CloseIcon />
              </IconButton>
            </HStack>
            <Text>
              view your tx :{" "}
              <Link
                textColor={"red"}
                href={"https://snowtrace.io/tx/" + txhash}
                isExternal
              >
                Link
              </Link>{" "}
            </Text>
          </Box>
        </>
      ),
    });
  };
  const errorToast = (reason) => {
    const id = Date.now().toString();
    toast({
      isClosable: true,
      duration: 18000,
      position: "bottom-left",
      id: id,
      render: () => (
        <>
          <Box
            shadow={"dark-lg"}
            rounded={"3xl"}
            bgColor="black"
            border={"2px"}
            padding={"0.5"}
            justifyContent="center"
            justifyItems={"center"}
            justifySelf={"center"}
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            textAlign="center"
            verticalAlign="center"
          >
            <HStack>
              <Heading flex={1} fontSize={"2xl"}>
                Error!
              </Heading>
              <IconButton
                onClick={() => {
                  toast.close(id);
                }}
                rounded="full"
                colorScheme={"blackAlpha"}
              >
                <CloseIcon />
              </IconButton>
            </HStack>
            <Text>{reason}</Text>
          </Box>
        </>
      ),
    });
  };

  const [bbbTokens, setBbbTokens] = useState([0]);
  const [bbucksBalance, setBbucksBalance] = useState(0);
  const [avaxBalance, setAvaxBalance] = useState(0);
  const [userAddress, setUserAddress] = useState("");
  const [nfts, setNfts] = useState();
  const [selected, setSelected] = useState();
  const BBBnoSigner = new ethers.Contract(
    "0x84FaB74795F51C3208379924c76abE6e5A36D392",
    bbbAbi,
    providerMainnet
  );
  console.log(nfts);
  const BBB = BBBnoSigner.connect(signer);

  useEffect(() => {
    signer
      .getBalance()
      .then((e) => setAvaxBalance(ethers.utils.formatEther(e)));
    signer.getChainId().then((e) => setNetwork(e));
    signer.getAddress().then((e) => setUserAddress(e));
    BBB.tokensOfOwner(userAddress).then((e) => {
      setBbbTokens(e);
    });
  }, [BBB, nfts, userAddress]);

  useMemo(() => {
    userAddress ? fetch("https://deep-index.moralis.io/api/v2/" + userAddress + "/nft?chain=0xa86a&token_addresses=0x84FaB74795F51C3208379924c76abE6e5A36D392", { method: "GET", headers: { accept: 'application/json', 'X-API-Key': "rTO3ivS5kbrUYLADjiK07aprt07UIKh9lcdEy346azAzfSGi6zfYLBA4z9C1MuE4" } }).then((nfts) => {
      nfts.json().then(e => {
          setNfts(e.result)
      })
  }) : setUserAddress(userAddress)
  }, [userAddress]);

  return (
    <>
      <Navbar />
      <div className="mt-4 lg:mt-0 px-4 sm:px-6 max-w-4xl mx-auto text-gray-800 relative z-40">
        <div className="flex justify-between items-center w-full py-4 px-4 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg mb-2">
          <h1 className="text-3xl font-extrabold">Wardrobe</h1>
        </div>
        {!nfts? (
          <button
            onClick={() => connect()}
            className="flex justify-center items-center w-full py-4 px-4 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
          >
            <h1 className="text-xl font-extrabold">
              Connect Wallet to see your BBBs
            </h1>
          </button>
        ):''}

        <div class="grid overflow-hidden lg grid-cols-4 auto-rows-auto gap-4 grid-flow-row">
          {nfts?.map((e, index) => {
              const metadata = JSON.parse(e.metadata);
              return (
                <a
                  className="hover:bg-gray-300 transition-all flex justify-center items-center flex-col py-4 px-4 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg font-extrabold"
                  key={index}
                  href={"/wardrobe/" + e.token_id}
                >
                  <img
                    className="rounded-lg"
                    src={metadata?.image.replace(
                      "ipfs://",
                      "https://gateway.ipfs.io/ipfs/"
                    )}
                  />
                  <p className="mt-4">{metadata?.name}</p>
                </a>
              );
            }
          )}
        </div>
      </div>
    </>
  );
}
