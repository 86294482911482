import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxGroup,
  Container,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Link,
  List,
  ListItem,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spacer,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import * as constants from "../bbb/constants";
import { CloseIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import traitRerollAbi from "../../abis/traitRerollAbi.json";
import bbbAbi from "../../abis/bbb.json";
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { IpfsImage } from "react-ipfs-image";
import { Card } from "@chakra-ui/card";
import Navbar from "../../components/Navbar";
import bruhAbi from "../../abis/bruh.json";

function shorten(text) {
  return text.substring(0, 5) + "..." + text.substring(39, 42);
}
let provider;
let providerWC = new WalletConnectProvider({
  rpc: { 43114: "https://api.avax.network/ext/bc/C/rpc" },
  chainId: 43114,
});
window.ethereum
  ? (provider = new ethers.providers.Web3Provider(window.ethereum))
  : (provider = new ethers.providers.Web3Provider(providerWC));

const connect = async () => {
  window.ethereum
    ? await provider.send("eth_requestAccounts", [])
    : await providerWC.enable();
};
const providerMainnet = new ethers.getDefaultProvider(
  "https://api.avax.network/ext/bc/C/rpc"
);
const providerTestnet = new ethers.getDefaultProvider(
  "https://api.avax-test.network/ext/bc/C/rpc"
);
const signer = provider.getSigner();

/*await Moralis.start({
    apiKey: "rTO3ivS5kbrUYLADjiK07aprt07UIKh9lcdEy346azAzfSGi6zfYLBA4z9C1MuE4",
  });
  
  async function GetNFTs() {
    const response = await Moralis.EvmApi.nft.getWalletNFTs({
      address: await signer.getAddress(),
      chain: chain,
    });
    
    return response.result;
  }*/

export default function Edit() {
  const { id } = useParams();
  const [network, setNetwork] = useState();

  const changeNetwork = async () => {
    if (network != 43114 && window.ethereum) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0xa86a",
          },
        ],
      });
    } else if (network != 43114 && !window.ethereum) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0xa86a",
          },
        ],
      });
    }
  };

  const toast = useToast();
  const infoToast = (heading, info) => {
    const id = Date.now().toString();
    toast({
      duration: 18000,
      position: "bottom-left",
      id: id,
      render: () => (
        <>
          <Box
            shadow={"dark-lg"}
            rounded={"3xl"}
            bgColor="black"
            border={"2px"}
            padding={"0.5"}
            justifyContent="center"
            justifyItems={"center"}
            justifySelf={"center"}
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            textAlign="center"
            verticalAlign="center"
          >
            <HStack>
              <Heading flex={1} fontSize={"2xl"}>
                {heading}
              </Heading>
              <IconButton
                onClick={() => {
                  toast.close(id);
                }}
                rounded="full"
                colorScheme={"blackAlpha"}
              >
                <CloseIcon />
              </IconButton>
            </HStack>
            <Text>{info}</Text>
          </Box>
        </>
      ),
    });
  };
  const txToast = (txhash) => {
    const id = Date.now().toString();
    toast({
      duration: 18000,
      position: "bottom-left",
      id: id,
      render: () => (
        <>
          <Box
            shadow={"dark-lg"}
            rounded={"3xl"}
            bgColor="black"
            border={"2px"}
            padding={"0.5"}
            justifyContent="center"
            justifyItems={"center"}
            justifySelf={"center"}
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            textAlign="center"
            verticalAlign="center"
          >
            <HStack>
              <Heading flex={1} fontSize={"2xl"}>
                Success!
              </Heading>
              <IconButton
                onClick={() => {
                  toast.close(id);
                }}
                rounded="full"
                colorScheme={"blackAlpha"}
              >
                <CloseIcon />
              </IconButton>
            </HStack>
            <Text>
              view your tx :{" "}
              <Link
                textColor={"red"}
                href={"https://snowtrace.io/tx/" + txhash}
                isExternal
              >
                Link
              </Link>{" "}
            </Text>
          </Box>
        </>
      ),
    });
  };
  const errorToast = (reason) => {
    const id = Date.now().toString();
    toast({
      isClosable: true,
      duration: 18000,
      position: "bottom-left",
      id: id,
      render: () => (
        <>
          <Box
            shadow={"dark-lg"}
            rounded={"3xl"}
            bgColor="black"
            border={"2px"}
            padding={"0.5"}
            justifyContent="center"
            justifyItems={"center"}
            justifySelf={"center"}
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            textAlign="center"
            verticalAlign="center"
          >
            <HStack>
              <Heading flex={1} fontSize={"2xl"}>
                Error!
              </Heading>
              <IconButton
                onClick={() => {
                  toast.close(id);
                }}
                rounded="full"
                colorScheme={"blackAlpha"}
              >
                <CloseIcon />
              </IconButton>
            </HStack>
            <Text>{reason}</Text>
          </Box>
        </>
      ),
    });
  };

  const [bbbTokens, setBbbTokens] = useState([0]);
  const [BruhBalance, setBruhBalance] = useState(0);
  const [avaxBalance, setAvaxBalance] = useState(0);
  const [userAddress, setUserAddress] = useState("");
  const [nfts, setNfts] = useState();
  const [selected, setSelected] = useState();
  const [tokenURI, setTokenURI] = useState("");
  const [metadata, setMetadata] = useState();
  const [approved, setApproved] = useState(0);
  const BBBnoSigner = new ethers.Contract(
    "0x84FaB74795F51C3208379924c76abE6e5A36D392",
    bbbAbi,
    providerMainnet
  );

  const bruhContract = new ethers.Contract('0xefb0475757652c9dd7B8bE2C6e0d99fB05950aB1', bruhAbi, signer);

  const rerollContract = new ethers.Contract(
    "0xa48A03535628dA62D2f17c13F97A97df4Fa314B7",
    traitRerollAbi,
    providerMainnet
  );
  const BBB = BBBnoSigner.connect(signer);
  const reroll = rerollContract.connect(signer);
  useEffect(() => {
    signer
      .getBalance()
      .then((e) => setAvaxBalance(ethers.utils.formatEther(e)));
    signer.getChainId().then((e) => setNetwork(e));
    signer.getAddress().then((e) => setUserAddress(e));
    BBBnoSigner.tokenURI(id).then((e) =>
      setTokenURI(e.replace("ipfs://", "https://gateway.ipfs.io/ipfs/"))
    );
    bruhContract.allowance(userAddress, "0xa48A03535628dA62D2f17c13F97A97df4Fa314B7").then((e) => setApproved(ethers.utils.formatEther(e)));
  }, [BBB, BBBnoSigner, bruhContract, id, nfts, userAddress]);

  console.log(approved);

  const [value, setValue] = useState(0);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  useMemo(() => {
    fetch(tokenURI, { method: "GET" })
      .then((res) => res.json())
      .then((e) => setMetadata(e));
    setName(metadata?.name);
    setDescription(metadata?.description);
  }, [metadata?.description, metadata?.name, tokenURI]);



  const rerollTrait = async () => {
    if(Number(approved) < 100){
      const tx = await bruhContract.approve('0xa48A03535628dA62D2f17c13F97A97df4Fa314B7', ethers.utils.parseEther("1000000000000000"));
      const rc = await tx.wait();
    } 
    const tx = await reroll.rerollWithBruh(id, selected);
    const rc = await tx.wait();
    infoToast("success", "");
  };
  const rename = async () => {
    if(Number(approved) < 100){
      const tx = await bruhContract.approve('0xa48A03535628dA62D2f17c13F97A97df4Fa314B7', ethers.utils.parseEther("1000000000000000"));
      const rc = await tx.wait();
    } 
    const tx = await reroll.renameWithBruh(id, name);
    const rc = await tx.wait();
    infoToast("success", "");
  };
  const changeDesc = async () => {
    if(Number(approved) < 100){
      const tx = await bruhContract.approve('0xa48A03535628dA62D2f17c13F97A97df4Fa314B7', ethers.utils.parseEther("1000000000000000"));
      const rc = await tx.wait();
    } 
    const tx = await reroll.changeDescriptionWithBruh(id, description);
    const rc = await tx.wait();
    infoToast("success", "");
  };
  const legendaryTokenIds = [123, 1344, 1960, 2985];
  if (id > 2999 || id < 0 || !isFinite(id)) {
    return "error";
  } else {
    return (
      <>
        <Navbar />
        <div className="mt-4 lg:mt-0 px-4 sm:px-6 max-w-4xl mx-auto text-gray-800 relative z-40">
          <div className="flex justify-between items-center w-full py-4 px-4 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg mb-2">
            <h1 className="text-3xl font-extrabold">{metadata?.name}</h1>

            <a
              href={
                "https://joepegs.com/item/0x84fab74795f51c3208379924c76abe6e5a36d392/" +
                id
              }
              className="text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl"
            >
              View on Joepegs
            </a>
          </div>

          <div className="custom-grid overflow-hidden py-4 px-4 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg">
            {/* <div className="grid overflow-hidden py-4 px-4 grid-cols-2 auto-rows-auto gap-4 grid-flow-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"> */}
            <div className="flex flex-col lg:flex-row gap-2">
              <img
                className="rounded-lg w-1/2 h-fit"
                src={
                  metadata?.image.includes("ipfs://")
                    ? metadata?.image.replace(
                        "ipfs://",
                        "https://gateway.ipfs.io/ipfs/"
                      )
                    : metadata?.image
                }
              />
              <div className="w-full">
                <div className="flex flex-col">
                  <label className="font-extrabold mb-1" for="nftname">
                    Name
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    name="nftname"
                    id="nftname"
                    className="rounded-lg p-2"
                  />
                  <button
                    isDisabled={
                      metadata?.image ==
                      "ipfs://bafybeiglyxykpokwhkcjrptn364tu373bunp73ehbeg7gqslbwvdgrn7ey/0.gif"
                    }
                    onClick={() => rename()}
                    className="my-2 w-full text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                  >
                    Rename
                  </button>
                </div>
                <div className="flex flex-col">
                  <label className="font-extrabold mb-1" for="nftdesc">
                    Description
                  </label>
                  <textarea
                    value={description}
                    rows="3"
                    className="rounded-lg p-2"
                    onChange={(e) => setDescription(e.target.value)}
                    name="nftdesc"
                    id="nftdesc"
                  ></textarea>
                  <button
                    isDisabled={
                      metadata?.image ==
                      "ipfs://bafybeiglyxykpokwhkcjrptn364tu373bunp73ehbeg7gqslbwvdgrn7ey/0.gif"
                    }
                    onClick={() => changeDesc()}
                    className="my-2 w-full text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                  >
                    Update Description
                  </button>
                </div>
              </div>
            </div>
            <div className="border-l-[1px] border-white">
              <div className="flex w-full pl-4 pr-2 flex-col mt-2 lg:mt-0">
                <p className="font-extrabold mb-2">Choose trait to reroll</p>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="background"
                    value={0}
                    onChange={(e) => setSelected(e.target.value)}
                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="background"
                  >
                    Background
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="skeletoncolor"
                    value={1}
                    onChange={(e) => setSelected(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="skeletoncolor"
                  >
                    Skeleton Color
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="costume"
                    value={2}
                    onChange={(e) => setSelected(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="costume"
                  >
                    Costume
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="eyes"
                    value={3}
                    onChange={(e) => setSelected(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="eyes"
                  >
                    Eyes
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="glasses"
                    value={4}
                    onChange={(e) => setSelected(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="glasses"
                  >
                    Glasses
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="hat"
                    value={5}
                    onChange={(e) => setSelected(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="hat"
                  >
                    Hat
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="mouth"
                    value={6}
                    onChange={(e) => setSelected(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="mouth"
                  >
                    Mouth
                  </label>
                </div>
                <button
                  className="mt-4 w-full text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                  isDisabled={
                    (legendaryTokenIds.indexOf(id) == -1 ? false : true) ||
                    metadata?.image ==
                      "ipfs://bafybeiglyxykpokwhkcjrptn364tu373bunp73ehbeg7gqslbwvdgrn7ey/0.gif"
                  }
                  onClick={() => rerollTrait()}
                >
                  Reroll Trait
                </button>
                <p className="text-black">Each change costs 50 $bruh2</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
